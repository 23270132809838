import {isPublishedOrCompleted} from '@/helpers/statusHandler'
import {InputSelector, InputSelectorProps} from '@/shared-components/InputSelector/InputSelector'
import {useAppSelector} from '@/store'
import {CountryType} from '@/types/CountriesResponse'
import {NBAStatus} from '@/types/NBAStatus'

export type CountrySelectorProps = InputSelectorProps & {
  status: NBAStatus
  // countries: string[]
  onValueChanged?: (countryCode: string) => void
}

export const CountrySelector = ({status, onValueChanged, disabled, ...props}: CountrySelectorProps) => {
  const countries = useAppSelector(state => state?.common?.countries || [])

  const isDisabled = disabled || countries.length <= 1 || isPublishedOrCompleted(status)

  return (
    <InputSelector {...props} onChange={evt => onValueChanged?.(evt.target.value)} disabled={isDisabled}>
      {!props.value && <option value={''}>Select</option>}
      {countries.map((country: CountryType, key: number) => (
        <option key={key} value={country.code}>
          {country.name}
        </option>
      ))}
    </InputSelector>
  )
}
