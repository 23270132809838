import {cn} from '@/helpers/shadcn/utils'
import {FieldLabel} from '@/shared-components/FieldLabel'
import {InfoOrError} from '@/shared-components/InfoOrError.tsx'
import {ComponentPropsWithoutRef} from 'react'

export interface InputSelectorProps extends ComponentPropsWithoutRef<'select'> {
  label?: string
  required?: boolean
  info?: string
  error?: string
}

export const InputSelector: React.FC<InputSelectorProps> = ({label, required, info, error, ...props}) => {
  return (
    <div className="flex w-full min-w-fit flex-col gap-2">
      <FieldLabel id={props.id} label={label} required={required} disabled={props.disabled} />

      <select
        {...props}
        data-testid={`${props.id}-selector`}
        data-cy={`${props.id}-selector`}
        data-error={error}
        style={{
          backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23${
            props.disabled ? 'A0A0A0' : '343a40'
          }' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
          backgroundPosition: 'calc(100% - 19px)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '9px',
        }}
        aria-selected={!!props.value}
        className={cn(
          'focus:shadow-none w-full appearance-none rounded border border-border bg-layer-01 px-3 py-[6px] text-sm font-normal focus:border-link focus:outline-none aria-selected:text-text-secondary aria-selected:focus:text-link',
          'border-border pr-10 text-sm disabled:border-transparent disabled:bg-button-disabled-background disabled:text-text-disabled data-[error]:border-support-error-primary',
          props.className
        )}
      />

      <InfoOrError info={info} error={error} id={props.id} />
    </div>
  )
}
