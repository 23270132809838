import {ENDPOINTS} from '@/config/constants'
import api, {HTTPMethod} from '@/helpers/api'
import {
  ConfigTypesRequestSchema,
  ConfigTypesResponseSchema,
  GetConfigTypesRequest,
  GetConfigTypesResponse,
} from '@/types/ConfigTypesResponse'
import {
  CountriesResponseSchema,
  GetCountriesRequest,
  GetCountriesRequestSchema,
  GetCountriesResponse,
} from '@/types/CountriesResponse'
import {
  GetLanguagesRequest,
  GetLanguagesRequestSchema,
  GetLanguagesResponse,
  LanguagesResponseSchema,
} from '@/types/LanguageResponse'
import {GetTasksRequest, GetTasksResponse, TasksRequestSchema, TasksResponseSchema} from '@/types/TasksResponse'
import {
  GetTaskTypesRequest,
  GetTaskTypesResponse,
  TaskTypesRequestSchema,
  TaskTypesResponseSchema,
} from '@/types/TaskTypesResponse'

export const getCountriesService = (signal?: AbortSignal) => {
  return api<GetCountriesRequest, GetCountriesResponse>({
    method: HTTPMethod.GET,
    path: ENDPOINTS.LOAD_COUNTRIES,
    requestSchema: GetCountriesRequestSchema,
    responseSchema: CountriesResponseSchema,
    signal,
  })()
}
export const getLanguagesService = (countryId: number, signal?: AbortSignal) => {
  return api<GetLanguagesRequest, GetLanguagesResponse>({
    method: HTTPMethod.GET,
    path: ENDPOINTS.LOAD_LANGUAGES,
    requestSchema: GetLanguagesRequestSchema,
    responseSchema: LanguagesResponseSchema,
    signal,
  })({countryId})
}

export const getTaskTypesService = (signal?: AbortSignal) => {
  return api<GetTaskTypesRequest, GetTaskTypesResponse>({
    method: HTTPMethod.GET,
    path: ENDPOINTS.LOAD_TASK_TYPES,
    requestSchema: TaskTypesRequestSchema,
    responseSchema: TaskTypesResponseSchema,
    signal,
  })()
}

export const getTasksService = (id: number | string, signal?: AbortSignal) => {
  return api<GetTasksRequest, GetTasksResponse>({
    method: HTTPMethod.GET,
    path: ENDPOINTS.LOAD_TASKS,
    requestSchema: TasksRequestSchema,
    responseSchema: TasksResponseSchema,
    signal,
  })({taskTypeId: id})
}

export const getConfigTypesService = (param: GetConfigTypesRequest, signal?: AbortSignal) => {
  return api<GetConfigTypesRequest, GetConfigTypesResponse>({
    method: HTTPMethod.GET,
    path: ENDPOINTS.CONFIG_TYPES,
    requestSchema: ConfigTypesRequestSchema,
    responseSchema: ConfigTypesResponseSchema,
    signal,
  })(param)
}
